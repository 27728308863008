<template>
    <div class="container">
        <div class="page-list">
            <div class="page-item" v-for="item in list">
                <div class="page-info">
                    <div class="page-line">
                        <span class="page-label">订单号：</span>
                        <span class="page-value">{{item.out_trade_no}}</span>
                    </div>
                    <div class="page-line">
                        <span class="page-label">支付金额：</span>
                        <span class="page-value">{{item.total_fee/100}}元</span>
                    </div>
                    <div class="page-line">
                        <span class="page-label">支付时间：</span>
                        <span class="page-value">{{item.id.slice(0,12)}}</span>
                    </div>
                </div>
            </div>
<!--            <div class="page-more" @click="getMore">{{tip}}</div>-->
        </div>
        <van-action-sheet
                v-model="show"
                :actions="actions"
                cancel-text="取消"
                close-on-click-action
                description="设置商品"
                @select="onSelect"
        />
    </div>
</template>
<script>
    export default {
        name: "orderList",
        data() {
            return {
                params: {
                    num: 0
                },
                device_type: '',
                list: [],
                total: 0,
                tip: '加载更多',
                show: false,
                actions: [{ name: '隐藏' , subname: '点击隐藏',type:'hide'}],
                obj: null,
                code: '',
                openid: '',
            }
        },
        components: {

        },
        created() {
            let _origin = window.location.href
            if (_origin.indexOf('localhost') >-1){
                return false
            }
            this.wx_zfb()
            if (this.device_type === 'weixin'){
                if (!!localStorage.openid) {
                    this.openid = localStorage.openid
                    this.getList()
                }else {
                    if (!!window.location.search){
                        this.code = window.location.search.split('?code=')[1].split('&')[0]
                        this.access_token()
                    }else {
                        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxceda6e7887ed0672&redirect_uri=${window.encodeURIComponent
                        (_origin)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
                    }
                }
            }else if(this.device_type === 'alipay'){
                console.log('ap.parseQueryString',ap.parseQueryString())
                if (!!localStorage.user_id) {
                    this.user_id = localStorage.user_id
                    // this.getList()
                }else {
                    if (!!window.location.search){
                        this.alipay_code = window.location.search.split('auth_code=')[1]
                        this.alipay_auth()
                    }else {
                        window.location.href = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002100672157&scope=auth_user&redirect_uri=${window.encodeURIComponent(_origin)}`
                    }
                }
            }else {
                this.getList()
            }
            // this.getList()
        },
        mounted() {

        },
        methods: {
            access_token() {
                let params = {
                    code: this.code
                }
                this.$util.ajax('/jh/access_token', 'POST', params).then(res => {
                    // console.log(res)
                    if (!!res.data.openid) {
                        localStorage.openid = res.data.openid
                        this.openid = res.data.openid
                        this.getList()
                    }
                })
            },
            alipay_auth() {
                let params = {
                    code: this.alipay_code
                }
                this.$util.ajax('/jh/alipay/auth', 'POST', params).then(res => {
                    // console.log(res)
                    if (!!res.data.user_id) {
                        localStorage.user_id = res.data.user_id
                        this.user_id = res.data.user_id
                    }
                })
            },
            wx_zfb() {
                // console.log(window.navigator)
                let ua = window.navigator.userAgent
                console.log(ua)
                if (ua.indexOf('MicroMessenger') > -1){
                    localStorage.device_type = 'weixin'
                }else if (ua.indexOf('AlipayClient') > -1){
                    localStorage.device_type = 'alipay'
                }else {
                    localStorage.device_type = 'h5'
                }
                this.device_type = localStorage.device_type
            },
            setCommodity(obj) {
                this.obj = obj
                this.show = true
            },
            onSelect(item) {
                console.log(item)
                if (item.type === 'hide'){
                    console.log(this.obj)
                    let params = {
                        code: this.obj.code,
                        img_keyword: "香烟"
                    }
                    this.$util.ajax('/jh/manage/set', 'POST', params).then(res => {
                        if (res.code !== 200) {
                            this.$toast('查询失败')
                            return false
                        }
                        this.$toast('设置成功')
                    })
                }
            },
            getList() {
                let params = {
                    openid: this.openid
                }
                this.$util.ajax('/jh/manage/order/search', 'POST', params).then(res => {
                    if (res.code !== 200) {
                        this.$toast('查询失败')
                        return false
                    }
                    this.list = res.data

                    // this.total = res.total
                    // if (params.num === 0){
                    //     this.list = res.value
                    // }else {
                    //     this.list = this.list.concat(res.value)
                    // }
                })
            },
            getMore() {
                if (this.list.length >= this.total){
                    this.tip = '已加载全部'
                    this.$toast('已加载全部')
                    return false
                }
                this.params.num ++
                this.getList()
            }
        }
    }
</script>

<style scoped lang="less">
    .container {
        width: 100%;
        box-sizing: border-box;
        padding: 6px 0;
        background: #000000;
        .page-list {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .page-item {
                width: 100%;
                color: #eee;
                box-sizing: border-box;
                padding: 0.2rem;
                border-bottom: 1px solid #292929;
            }
            /*.page-item {*/
            /*    width: calc(50% - 3px);*/
            /*    height: 280px;*/
            /*    margin-bottom: 8px;*/
            /*    background-size: cover;*/
            /*    overflow: hidden;*/
            /*    display: flex;*/
            /*    align-items: flex-end;*/
            /*    .page-info {*/
            /*        width: 100%;*/
            /*        padding: 6px;*/
            /*        background: rgba(255,255,255,0.2);*/
            /*        color: #fff;*/
            /*        .page-title {*/
            /*            width: 100%;*/
            /*            display: flex;*/
            /*            font-size: 0.3rem;*/
            /*            color: #000000;*/
            /*            padding-bottom: 6px;*/
            /*        }*/
            /*        .page-price {*/
            /*            width: 100%;*/
            /*            font-size: 0.26rem;*/
            /*            color: red;*/
            /*        }*/
            /*    }*/
            /*}*/
            .page-more {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 12px 0;
                color: #fff;
                font-size: 0.24rem;
            }
        }
    }
</style>